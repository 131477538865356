import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { Container } from "@material-ui/core";
import useGaTracker from "./useGaTracker";

// import Admin from "../components/admin/Admin";
// import UploadDS from "../components/admin/upload-daily-sicha/UploadDS";
// import UploadTopics from "../components/admin/upload-topics/UploadTopics";
// import Donate from "../components/donate/Donate";
// import PageNotFound from "../components/page-not-found/PageNotFound";
// import Dedication from "../components/dedication/Dedication";
// import Download from "../components/download/Download";
// import Downloads from "../components/admin/downloads/Downloads";
// import Receipts from "../components/admin/receipts/Receipts";
// import ReceiptList from "../components/admin/receipt-list/ReceiptList";
// import DownloadsList from "../components/admin/downloads-list/DownloadsList";
// import SubscriberList from "../components/admin/subscriber-list/SubscriberList";
// import OtherOptions from "../components/other-options/OtherOptions";
// import DedicationControl from "../components/admin/dedication-control/DedicationControl";
// import Notification from "../components/notification/Notification";
// import NotificationList from "../components/admin/notification-list/NotificationList";
// import Subscription from "../components/subscription/Subscription";
// import DeleteTopics from "../components/admin/delete-topics/DeleteTopics";
// import AddAds from "../components/admin/add-ads/AddAds";
// import GetSichosList from "../components/admin/get-sichos-list/GetSichosList";
// import UploadSichosFiles from "../components/admin/upload-sichos-files/UploadSichosFiles";
// import Managments from "../components/admin/management/Management";
// import Dedications from "../components/admin/dedications/Dedications";
// import DailySicha from "../components/daily-sicha/DailySicha";
// import Topics from "../components/topics/Topics";
// import About from "../components/about/About";
// import SearchSicha from "../components/search-sicha/SearchSicha";
// import Login from "../components/login/Login";

const DailySicha = lazy(() => import("../components/daily-sicha/DailySicha"));
const Topics = lazy(() => import("../components/topics/Topics"));
const About = lazy(() => import("../components/about/About"));
const SearchSicha = lazy(() => import("../components/search-sicha/SearchSicha"));
const Login = lazy(() => import("../components/login/Login"));
const PageNotFound = lazy(() => import("../components/page-not-found/PageNotFound"));
const Download = lazy(() => import("../components/download/Download"));
const OtherOptions = lazy(() => import("../components/other-options/OtherOptions"));
const Notification = lazy(() => import("../components/notification/Notification"));
const Donate = lazy(() => import("../components/donate/Donate"));
const Dedication = lazy(() => import("../components/dedication/Dedication"));
const UploadSichosFiles = lazy(() => import("../components/admin/upload-sichos-files/UploadSichosFiles"));
const Managments = lazy(() => import("../components/admin/management/Management"));
const Dedications = lazy(() => import("../components/admin/dedications/Dedications"));
const Downloads = lazy(() => import("../components/admin/downloads/Downloads"));
const Receipts = lazy(() => import("../components/admin/receipts/Receipts"));
const ReceiptList = lazy(() => import("../components/admin/receipt-list/ReceiptList"));
const DownloadsList = lazy(() => import("../components/admin/downloads-list/DownloadsList"));
const SubscriberList = lazy(() => import("../components/admin/subscriber-list/SubscriberList"));
const DedicationControl = lazy(() => import("../components/admin/dedication-control/DedicationControl"));
const GetSichosList = lazy(() => import("../components/admin/get-sichos-list/GetSichosList"));
const AddAds = lazy(() => import("../components/admin/add-ads/AddAds"));
const DeleteTopics = lazy(() => import("../components/admin/delete-topics/DeleteTopics"));
const NotificationList = lazy(() => import("../components/admin/notification-list/NotificationList"));
const UploadTopics = lazy(() => import("../components/admin/upload-topics/UploadTopics"));
const UploadDS = lazy(() => import("../components/admin/upload-daily-sicha/UploadDS"));
const Admin = lazy(() => import("../components/admin/Admin"));
const DedicationCanada = lazy(() => import("../components/dedication/DedicationCanada"));

export default function RouterConfig(props) {
  useGaTracker();

  const children = (
    <>
      <Admin />
      <Container className="container inline-grid">
        <Route path="/admin" exact>
          <Managments />
        </Route>
        <Route path="/admin/upload-files" exact>
          <UploadSichosFiles />
        </Route>
        <Route path="/admin/topics" exact>
          <UploadTopics />
        </Route>
        <Route path="/admin/upload-ds" exact>
          <UploadDS />
        </Route>
        <Route path="/admin/dedications" exact>
          <Dedications />
        </Route>
        <Route path="/admin/dedication-control" exact>
          <DedicationControl setSpinner={props.setSpinner} />
        </Route>
        <Route path="/admin/downloads" exact>
          <Downloads />
        </Route>
        <Route path="/admin/receipts" exact>
          <Receipts />
        </Route>
        <Route path="/admin/receipt-list" exact>
          <ReceiptList />
        </Route>
        <Route path="/admin/downloads-list" exact>
          <DownloadsList />
        </Route>
        <Route path="/admin/subscribers-list" exact>
          <SubscriberList />
        </Route>
        <Route path="/admin/notification-list" exact>
          <NotificationList />
        </Route>
        <Route path="/admin/delete-topics" exact>
          <DeleteTopics />
        </Route>
        <Route path="/admin/add-ads" exact>
          <AddAds />
        </Route>
        <Route path="/admin/get-sichos-list" exact>
          <GetSichosList />
        </Route>
      </Container>
      </>
  );

  return (
    <Suspense fallback={<div>loading...</div>}>
      <Switch>
        <Route path="/" exact={true}>
          <DailySicha setSpinner={props.setSpinner} />
        </Route>
        <Route path="/dailysicha.htm">
          <Redirect to="/" />
        </Route>
        <Route path="/search">
          <SearchSicha auth={props.auth} />
        </Route>
        <Route path="/topics">
          <Topics setSpinner={props.setSpinner} />
        </Route>
        <Route path="/dedication">
          <Dedication setSpinner={props.setSpinner} />
        </Route>
        <Route path="/dedication-canada">
          <DedicationCanada setSpinner={props.setSpinner} />
        </Route>
        <Route path="/download">
          <Download setSpinner={props.setSpinner} />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/login">
          <Login setAuth={props.setAuth} />
        </Route>
        <Route path="/donate">
          <Donate setSpinner={props.setSpinner} />
        </Route>
        <Route path="/notification">
          <Notification setSpinner={props.setSpinner} />
        </Route>
        {/* <Route path="/notification">
          <Subscription setSpinner={props.setSpinner} />
        </Route> */}
        <Route path="/other-options">
          <OtherOptions setSpinner={props.setSpinner} />
        </Route>
        <Route
          path="/donatecanada"
          component={() => {
            window.location.replace(
              "https://www.thechai.com/templates/articlecco_cdo/aid/5596983/jewish/Donate-The-Daily-Sicha.htm"
            );
            return null;
          }}
        />
        <ProtectedRoute path="/admin" child={children} />
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </Suspense>
  );
}
